import axios from 'axios';
import { VIEW_CUSTOM_API_BASE_URL } from '../../config';
import { getApiToken } from '../../utils';

interface NoticeListRequest {
	title?: string;
	page: number;
	pageView: number;
	bannerType: number;
}
interface NoticeDetailRequest {
	uid: number;
}
interface NoticeResponseCommon {
	nowDate: string;
	code: number;
	message: string;
}
interface NoticeListResponse extends NoticeResponseCommon {
	totalCnt: number;
	period: NoticeListItem[];
}
interface NoticeListItem {
	category: number;
	content: string;
	gbnCd: number;
	imgUrl?: string;
	index: number;
	isMainExp: number;
	no: number;
	noticeIdx: number;
	regDate: string;
	stCd: number;
	title: string;
	viewCnt: string;
}

interface IAjaxOption {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	headers?: any;
	params?: any;
	data?: any;
	baseURL?: string;
	cancelToken?: any;
}

const $http = (option: IAjaxOption) => (useToken: boolean) => {
	if (useToken) {
		option.headers = {
			...option.headers,
			// Authorization: '61d7060a-b4bc-44b6-a148-c15e0c07cb70',
			'authoried-token': getApiToken(), // api 구조 변경 25.02.05
		};
	}
	return axios(option);
};

export const getNoticeList = (request: NoticeListRequest) => {
	return new Promise((resolve: (response: NoticeListResponse) => void, reject: (e: Error) => void) => {
		$http({
			url: '/api/advertisement',
			method: 'GET',
			params: { ...request, bannerType: 11, useLandingReply: false },
			baseURL: VIEW_CUSTOM_API_BASE_URL,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};

export const getNoticeDetail = (request: NoticeDetailRequest) => {
	return new Promise((resolve: (response: NoticeListItem) => void, reject: (e: Error) => void) => {
		$http({
			url: `/api/advertisement/${request.uid}`,
			method: 'GET',
			baseURL: VIEW_CUSTOM_API_BASE_URL,
		})(true)
			.then((response) => resolve(response.data))
			.catch((e) => {
				const { response } = e;
				if (!response) {
					reject(e);
				} else {
					reject(response.data);
				}
			});
	});
};
